// Mapbox
import mapboxgl from 'mapbox-gl';

// RaptorUI
import { Stack } from '@raptormaps/layout';

// Components
import { SectionCollapsible } from '@/pages/MissionPlanner/components/MissionPlanningSidebar/MissionPlanningSidebar.styles';
import EquipmentSlider from './EquipmentSlider';

// Mapbox Layers
import InverterBlockLayers, {
  SECTION_ZONE_CLUSTER_FILL_OPACITY,
} from '../Map/layers/InverterBlockLayers';
import RowLayers from '../Map/layers/GeospatialRowLayers';
import CombinerLayers from '../Map/layers/CombinerLayers';
import InverterLayers from '../Map/layers/InverterLayers';
import ModuleLayers from '../Map/layers/ModuleLayers';
import PyranometerLayers from '../Map/layers/PyranometerLayers';
import StringLayers from '../Map/layers/StringLayers';

// Hooks
import {
  useGetEquipmentModulesAndStrings,
  useGetInverterBlocks,
  useGetEquipmentRows,
  useGetEquipmentCombiners,
  useGetEquipmentInverters,
  useGetEquipmentPyranometers,
} from '../../hooks/useEquipment';

// Types
import { EquipmentLayerData } from './digitalTwin.d';

interface DigitalTwinSectionProps {
  map: mapboxgl.Map;
  solarFarmId: number;
  activeRowId: number;
}

const DigitalTwinSection = ({
  map,
  solarFarmId,
  activeRowId,
}: DigitalTwinSectionProps) => {
  const { data: modulesAndStrings } = useGetEquipmentModulesAndStrings(
    solarFarmId,
    activeRowId,
  );
  const { data: inverterBlocks } = useGetInverterBlocks(solarFarmId);
  const { data: geospatialRows } = useGetEquipmentRows(solarFarmId);
  const { data: combiners } = useGetEquipmentCombiners(solarFarmId);
  const { data: inverters } = useGetEquipmentInverters(solarFarmId);
  const { data: pyranometers } = useGetEquipmentPyranometers(solarFarmId);

  const sliders: EquipmentLayerData[] = [
    { layers: ModuleLayers, name: 'Module', data: modulesAndStrings?.modules },
    { layers: StringLayers, name: 'String', data: modulesAndStrings?.strings },
    {
      layers: InverterBlockLayers,
      name: 'Inverter Block',
      data: inverterBlocks,
      defaultOpacity: SECTION_ZONE_CLUSTER_FILL_OPACITY * 100,
    },
    { layers: RowLayers, name: 'Row', data: geospatialRows },
    { layers: CombinerLayers, name: 'Combiner', data: combiners },
    { layers: InverterLayers, name: 'Inverter', data: inverters },
    { layers: PyranometerLayers, name: 'Pyranometer', data: pyranometers },
  ];
  return (
    <SectionCollapsible
      title="Digital Twin"
      id="DigitalTwin-section"
      iconColor="neutral_900"
      variant="ghost"
    >
      <Stack>
        {sliders.map((equipmentLayer: EquipmentLayerData) => (
          <EquipmentSlider
            map={map}
            key={equipmentLayer.layers[0].id}
            equipmentLayerData={equipmentLayer}
            defaultOpacity={equipmentLayer?.defaultOpacity}
            disabled={
              !equipmentLayer.data || equipmentLayer.data?.features?.length <= 0
            }
            disabledTooltipMessage={
              equipmentLayer.name === 'String' ||
              (equipmentLayer.name === 'Module' && activeRowId === null)
                ? 'Select a row to enable string and module visibility controls.'
                : `No ${equipmentLayer.name.toLowerCase()} data found for this solar site.`
            }
          />
        ))}
      </Stack>
    </SectionCollapsible>
  );
};

export default DigitalTwinSection;
