import { LayerSpecification } from 'mapbox-gl';

const FlightPath: LayerSpecification = {
  id: 'flightPath',
  type: 'line',
  source: 'flightPath',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#F7455D', // red
    // 'line-color': '#888', // grey
    'line-width': 2,
  },
};

const Waypoints: LayerSpecification = {
  id: 'waypoints',
  type: 'symbol',
  source: 'waypoints',
  layout: {
    'text-field': ['get', 'name'],
    'text-font': ['Open Sans Bold'],
    'text-offset': [0, 2.5],
    'text-size': 10,
    'icon-image': 'custom-marker',
    'icon-size': 0.4,
    'icon-rotate': ['get', 'rotation'],
    'icon-rotation-alignment': 'map',
    'icon-anchor': 'center',
    'icon-allow-overlap': true,
  },
  paint: {
    'text-color': '#000000',
    'text-halo-color': 'rgba(255,255,255,0.8)',
    'text-halo-width': 2,
    'icon-opacity': 1,
  },
};

const NonCalibratedFlightPath: LayerSpecification = {
  id: 'nonCalibratedFlightPath',
  type: 'line',
  source: 'nonCalibratedFlightPath',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': 'light blue',
    'line-width': 1,
  },
};

const Polygon: LayerSpecification = {
  id: 'polygon',
  type: 'line',
  source: 'polygon',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#06B6D4',
    'line-width': 2,
    'line-opacity': 1,
  },
};

export default [Polygon, FlightPath, Waypoints, NonCalibratedFlightPath];
