import { useState, ChangeEvent, useEffect } from 'react';
import exifr from 'exifr';
import CalibrationMap from './CalibrationMap/CalibrationMap';
import MapContextProvider from './CalibrationMap/MapContext';
import mapboxgl from 'mapbox-gl';
import { Button } from '@raptormaps/button';
import rhumbBearing from '@turf/rhumb-bearing';
import rhumbDistance from '@turf/rhumb-distance';
import { useSolarFarmById } from '../../shared/hooks/useSolarFarms';
import Mixpanel from '../../shared/utils/mixpanel/utils';
import MixpanelEvents from '../../shared/utils/mixpanel/events';
import BackgroundSection from '../../shared/components/Background/Background';
import DigitalTwinSection from '../../shared/components/DigitalTwin/DigitalTwin';
import { PageProps } from '../../shared/types/page';

interface ImageMetadata {
  name: string;
  type: string;
  size: number;
  lastModified: number;
  exif?: ExifData; // Store entire Exif data
}

interface ExifData {
  latitude: number;
  longitude: number;
  [key: string]: string | number;
}

interface ImageMetadataDisplayProps {
  metadata: ImageMetadata | null;
  markerLngLat: Array<number> | [];
}

function ImageMetadataDisplay({
  metadata,
  markerLngLat,
}: ImageMetadataDisplayProps) {
  if (!metadata) {
    return <div>No image selected</div>;
  }

  if (markerLngLat.length === 0) {
    return <div>No comparison point chosen</div>;
  }

  return (
    <div>
      {metadata.exif && (
        <div>
          <p>
            Rhumb Bearing:{' '}
            {rhumbBearing(
              [markerLngLat[0], markerLngLat[1]],
              [metadata.exif.longitude, metadata.exif.latitude],
            )}
          </p>
          <p>
            Rhumb Distance:{' '}
            {rhumbDistance(
              [markerLngLat[0], markerLngLat[1]],
              [metadata.exif.longitude, metadata.exif.latitude],
            )}
          </p>
        </div>
      )}
    </div>
  );
}

function Calibration({ solarFarmId }: PageProps) {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageMetadata, setImageMetadata] = useState<ImageMetadata | null>(
    null,
  );
  const [markerLngLat, setMarkerLngLat] = useState([]);
  const [marker, setMarker] = useState(null);
  const [imageMarker] = useState(new mapboxgl.Marker({ color: 'green' }));
  const [drawTool, setDrawTool] = useState(null);
  const [activeRowId, setActiveRowId] = useState<number | null>(null);
  const [map, setMap] = useState<mapboxgl.Map>(null);

  const { data: activeSolarFarm } = useSolarFarmById(solarFarmId);
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];

    if (selectedFile) {
      setSelectedImage(selectedFile);
    }
  };

  useEffect(() => {
    Mixpanel.track(MixpanelEvents.CalibrationPageLoaded);
  }, []);

  useEffect(() => {
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = async event => {
        const arrayBuffer = event.target?.result as ArrayBuffer;
        const exifData = await exifr.parse(arrayBuffer);

        const metadata: ImageMetadata = {
          name: selectedImage.name,
          type: selectedImage.type,
          size: selectedImage.size,
          lastModified: selectedImage.lastModified,
          exif: exifData,
        };
        setImageMetadata(metadata);
        // Fly to location of image chosen and set marker point
        const imageLngLat = [metadata.exif.longitude, metadata.exif.latitude];
        map.flyTo({ center: imageLngLat });
        imageMarker.setLngLat(imageLngLat);
        imageMarker.addTo(map);
      };
      reader.readAsArrayBuffer(selectedImage);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (activeSolarFarm) {
      map?.setZoom(15);
      map?.setCenter([activeSolarFarm?.longitude, activeSolarFarm?.latitude]);
    }
  }, [activeSolarFarm]);

  // Function to add/remove the calibration point
  let takeoffMarker: mapboxgl.Marker | null = null;
  const handleAddTakeoffpoint = () => {
    if (marker) {
      marker.remove();
      setMarker(null);
    } else {
      takeoffMarker = new mapboxgl.Marker({ draggable: true })
        .setLngLat(map.getCenter())
        .addTo(map);
      setMarker(takeoffMarker);
      setMarkerLngLat([
        takeoffMarker.getLngLat().lng,
        takeoffMarker.getLngLat().lat,
      ]);

      takeoffMarker.on('dragend', onDragEnd);
    }
    function onDragEnd() {
      setMarkerLngLat([
        takeoffMarker.getLngLat().lng,
        takeoffMarker.getLngLat().lat,
      ]);
    }
  };

  return (
    <>
      <div className="formDrawer">
        <div className="form">
          <div>
            <label className="input-label"> Solar Site: </label>
            <h3>{activeSolarFarm?.name}</h3>
          </div>
          <h4>Calibration - EXPERIMENTAL!!</h4>
          <p>
            {' '}
            Calibration should help align the overlays with the real world.
            Upload an image taken by the drone when it is centered above an
            easily identifiable point with the camera pointed straight down
            (nadir).
          </p>
          <input type="file" accept="image/*" onChange={handleImageChange} />

          <p>
            {' '}
            Now add the calibration point to the map and move it so its pointing
            at the same location that is at the center of the image.{' '}
          </p>
          <Button size="medium" variant="link" onClick={handleAddTakeoffpoint}>
            {!marker ? 'Create' : 'Delete'} Calibration Point
          </Button>
          <p>
            {' '}
            Finally, copy the numbers below to a notepad and return to the
            flight planning page where you can paste these numbers to calibrate
            the flight path.
          </p>
          <ImageMetadataDisplay
            metadata={imageMetadata}
            markerLngLat={markerLngLat}
          />
          <BackgroundSection map={map} solarFarmId={solarFarmId} />
          <DigitalTwinSection
            map={map}
            solarFarmId={solarFarmId}
            activeRowId={activeRowId}
          />
        </div>
      </div>
      <MapContextProvider>
        <CalibrationMap
          activeSolarFarm={activeSolarFarm}
          map={map}
          drawTool={drawTool}
          setDrawTool={setDrawTool}
          solarFarm={activeSolarFarm}
          activeRowId={activeRowId}
          setActiveRowId={setActiveRowId}
          setMap={setMap}
        />
      </MapContextProvider>
    </>
  );
}

export default Calibration;
