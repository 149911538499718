// React
import { useState } from 'react';

// Mapbox
import mapboxgl from 'mapbox-gl';

// Mixpanel
import Mixpanel from '../../utils/mixpanel/utils';
import MixpanelEvents from '../../utils/mixpanel/events';

// RaptorUi
import { IconButton, IconButtonProps } from '@raptormaps/button';
import { Row, Stack } from '@raptormaps/layout';
import { Text } from '@raptormaps/text';
import { Tooltip } from '@raptormaps/tooltip';

// Components
import AsBuiltController from './AsBuiltController';
import MapLayerSlider from '../MapLayerSlider/MapLayerSlider';

// Styled Components
import { SectionCollapsible } from '@/pages/MissionPlanner/components/MissionPlanningSidebar/MissionPlanningSidebar.styles';

// Hooks
import { useGetAsBuilt } from '../../hooks/useAsBuilt';

// Customer Api Types
import { TileMap } from '@raptormaps/customer-farm-api-client-ts';

interface BackgroundSectionProps {
  map: mapboxgl.Map;
  solarFarmId: number;
  useDroneIcon?: boolean;
}

const BackgroundSection = ({
  map,
  solarFarmId,
  useDroneIcon = true,
}: BackgroundSectionProps) => {
  const [icon, setIcon] = useState<IconButtonProps['icon']>('Eye');
  const { data: asBuilts } = useGetAsBuilt(solarFarmId);

  const handleAddRemoveDroneMarker = () => {
    Mixpanel.track(MixpanelEvents.PlanWaypointIconToggle);
    if (map.getPaintProperty('waypoints', 'icon-opacity') == 1) {
      map.setPaintProperty('waypoints', 'icon-opacity', 0);
      setIcon('EyeSlash');
    } else {
      map.setPaintProperty('waypoints', 'icon-opacity', 1);
      setIcon('Eye');
    }
  };

  return (
    <SectionCollapsible
      title="Background"
      id="background-section"
      iconColor="neutral_900"
      variant="ghost"
    >
      <Stack>
        {useDroneIcon ? (
          <Row align="center" justify="space-between">
            <Text htmlFor="as-build-opactiy">Drone Icons</Text>
            <IconButton
              aria-label="toggle drone icons"
              icon={icon}
              size="medium"
              variant="secondary"
              onClick={handleAddRemoveDroneMarker}
            />
          </Row>
        ) : (
          <></>
        )}

        {/* If there are tiles sets coming from farm builder and there are no custom as_builts hosted on */}
        {asBuilts?.length ? (
          asBuilts.map((asBuilt: TileMap) => (
            <AsBuiltController map={map} key={asBuilt.id} asBuilt={asBuilt} />
          ))
        ) : (
          <Tooltip
            colorVariant="dark"
            delay={100}
            placement={{
              align: 'center',
              side: 'right',
            }}
            tip={'No background layers available'}
            shouldWrapChildren={true}
          >
            <MapLayerSlider
              name="As-Built Opacity"
              opacity={0}
              layers={[]}
              visible={false}
              disabled={true}
              handleChangeOpacity={() => {
                /*  */
              }}
              handleHideShowLayer={() => {
                /*  */
              }}
            />
          </Tooltip>
        )}
      </Stack>
    </SectionCollapsible>
  );
};

export default BackgroundSection;
