// External Dependencies
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseFormReturn } from 'react-hook-form';
import mapboxgl from 'mapbox-gl';
import { IssueMissionResponse } from '@raptormaps/dji-dock-api-ts';

// RaptorUI
import { Text } from '@raptormaps/text';
import { Button } from '@raptormaps/button';
import { Sidebar } from '@raptormaps/sidebar';
import { Row, Stack } from '@raptormaps/layout';
import { TextInput } from '@raptormaps/text-input';
import theme from '@raptormaps/theme';
import { useToast } from '@raptormaps/toast';

// Components
import DigitalTwinSection from '@/shared/components/DigitalTwin/DigitalTwin';
import BackgroundSection from '@/shared/components/Background/Background';
import { Divider, SideBarContent } from '@/shared/styles/sidebar.styles';
import {
  MissionTaskFloatingSidebarContainer,
  MissionTaskCollapsableContainer,
} from '../CreateMissionTask/CreateMissionTask.styles';
import { FullWidthStack } from '@/shared/styles/sidebar.styles';
import { ComponentLoader } from '@/shared/components/ComponentLoader/ComponentLoader';
import { DockDeviceCard } from '@/shared/components/DockDeviceCard/DockDeviceCard';
import { MissionTaskCard } from '../FlightPlanner/components/MissionTaskCard';
import { MissionCardSimple } from '@/shared/components/MissionCard/MissionCardSimple';
import { FlightIdRow } from './ExecuteFlight.styles';
import { IssuedMissionCard } from './components/IssuedMissionCard';
import { ConfirmationModal } from '@/shared/components/ConfirmationModal/ConfirmationModal';

// hooks
import { useGetFlight } from '@/shared/hooks/useFlights';
import { useDockAndDevices } from '@/shared/hooks/useDockDevices';
import { useGetMissionTask } from '@/shared/hooks/useMissionTasks';
import { useUrlParams, useUrlSearchParams } from '@/shared/hooks/useUrlParams';
import {
  useDockMission,
  useExecuteFlight,
} from '@/shared/hooks/useDockWaylines';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

// constants
import { MISSION_TASK_FORM_FIELDS } from '../../missionTaskFormConstants';
import { GrowthbookFlags } from '@/shared/utils/GrowthbookUtils';

// Utils
import {
  TelemetryEmitter,
  TELEMETRY_EVENTS,
} from '@/shared/stores/TelemetryWebsocket/TelemetryEmitter';

interface ExecuteFlightProps {
  solarFarmId: number;
  map: mapboxgl.Map;
  activeRowId: number;
  missionTaskForm: UseFormReturn;
}

export const ExecuteFlight = ({
  solarFarmId,
  map,
  activeRowId,
  missionTaskForm,
}: ExecuteFlightProps) => {
  const navigate = useNavigate();
  const toast = useToast();
  const flightId = useUrlParams<number>('flightId', 'number');
  const [flightIdInput, setFlightIdInput] = useState<number>(flightId);
  const [response, setResponse] = useState<IssueMissionResponse>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const realFlight = useUrlSearchParams<boolean>('realFlight', 'boolean');
  const { mutateAsync: uploadDockMission } = useDockMission(
    solarFarmId,
    realFlight,
  );
  const { mutateAsync: executeFlight } = useExecuteFlight();

  const { data: flight } = useGetFlight({ solarFarmId, flightId });
  const { data: missionTask, isLoading: isLoadingMissionTask } =
    useGetMissionTask(flight?.missionTaskId, solarFarmId);
  const { activeDockDevice, isLoading: isLoadingDevices } = useDockAndDevices({
    solarFarmId: solarFarmId,
    dockId: missionTask?.dockId,
  });

  const showExecuteButton = useFeatureIsOn(GrowthbookFlags.EXECUTE_FLIGHT);

  useEffect(() => {
    // update mission on map
    missionTaskForm.setValue(
      MISSION_TASK_FORM_FIELDS.detailViewMissionId,
      flight?.missionId,
    );
  }, [flight]);

  useEffect(() => {
    TelemetryEmitter.on(TELEMETRY_EVENTS.FLIGHT_TASK_READY, msg => {
      const flightIds = msg?.data?.flight_ids;
      if (flightIds) {
        toast.success(`Flight ids ${flightIds} are ready to be executed.`);
      }
    });

    return () => {
      TelemetryEmitter.off(TELEMETRY_EVENTS.FLIGHT_TASK_READY);
    };
  }, []);

  const handleRenderCards = () => {
    if (response) {
      return (
        <>
          <Text variant="paragraph_small" align="left">
            Mission Issued
          </Text>
          <IssuedMissionCard response={response} />
        </>
      );
    } else if (flight) {
      return (
        <>
          <Text variant="paragraph_small" align="left">
            Mission Task
          </Text>
          <MissionTaskCard
            isLoading={isLoadingMissionTask}
            missionTask={missionTask}
          />
          <Stack style={{ width: '100%' }}>
            <Row align="center" justify="space-between">
              <Text variant="paragraph_small" align="left">
                Mission Route
              </Text>
            </Row>
            {isLoadingMissionTask ? (
              <ComponentLoader message={'Loading Mission...'} />
            ) : (
              missionTask && (
                <MissionCardSimple
                  mission={missionTask?.missions[0]}
                  isSelected={false}
                />
              )
            )}
            <Row align="center" justify="space-between">
              <Text variant="paragraph_small" align="left">
                Device
              </Text>
            </Row>
            {isLoadingDevices ? (
              <ComponentLoader message={'Loading Device...'} />
            ) : (
              <>
                {activeDockDevice && (
                  <DockDeviceCard
                    dockDevice={activeDockDevice}
                    isSelected={activeDockDevice}
                  />
                )}
              </>
            )}
          </Stack>
        </>
      );
    }
  };

  return (
    <>
      <MissionTaskFloatingSidebarContainer>
        <Sidebar title={'Execute Mission'} defaultOpen={true}>
          <SideBarContent align="start">
            <Button
              icon="ArrowLeft"
              iconPosition="left"
              size="small"
              variant="tertiary"
              role="link"
              style={{ fontWeight: '600', marginLeft: '-14px' }}
              onClick={() => {
                navigate({
                  pathname: flight?.missionTaskId
                    ? `/plan-task/${flight?.missionTaskId}`
                    : '/plan-task/',
                  search: `solar_farm_id=${solarFarmId}`,
                });
              }}
            >
              Back
            </Button>

            <FlightIdRow noWrap align="center">
              <Text>Flight Id: </Text>
              <TextInput
                name="flightID"
                inputSize="small"
                inputType="number"
                placeholder="Enter Flight Id..."
                value={flightIdInput}
                onChange={e => {
                  setFlightIdInput(
                    Number(e.target.value) ? Number(e.target.value) : null,
                  );
                }}
                style={{ color: theme.colors.black }}
              />
              <Button
                size="small"
                disabled={flightIdInput === flightId}
                variant={flightIdInput ? 'primary' : 'tertiary'}
                onClick={() => {
                  navigate({
                    pathname: flightIdInput
                      ? `/flight/${flightIdInput}`
                      : '/flight/',
                    search: `?solar_farm_id=${solarFarmId?.toString() || ''}`,
                  });
                }}
              >
                {flightIdInput ? 'Go to Flight' : 'Clear Flight'}
              </Button>
            </FlightIdRow>

            <Divider />

            {handleRenderCards()}
          </SideBarContent>
          <MissionTaskCollapsableContainer>
            <FullWidthStack style={{ width: '100%' }}>
              <Button
                variant="primary"
                disabled={!flight}
                style={{ marginTop: '10px' }}
                onClick={() => {
                  const dockMissionInput = {
                    dockSn: activeDockDevice?.deviceSn,
                    flightId: String(flightId),
                  };
                  uploadDockMission(dockMissionInput, {
                    onSuccess: response => {
                      if (response?.status === 'ok') {
                        setResponse(response);
                      }
                    },
                  });
                }}
              >
                {'Issue Flight'}
              </Button>
              {showExecuteButton && (
                <Button
                  variant="secondary"
                  disabled={!flight || !realFlight}
                  style={{ marginTop: '10px' }}
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  {'Execute Flight'}
                </Button>
              )}
            </FullWidthStack>
            <DigitalTwinSection
              solarFarmId={solarFarmId}
              map={map}
              activeRowId={activeRowId}
            />
            <BackgroundSection
              map={map}
              solarFarmId={solarFarmId}
              useDroneIcon={false}
            />
          </MissionTaskCollapsableContainer>
        </Sidebar>
        <ConfirmationModal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          handleCancel={() => setModalOpen(false)}
          handleConfirm={() => {
            setModalOpen(false);
            executeFlight({
              dockSn: activeDockDevice?.deviceSn,
              flightId: String(flightId),
            });
          }}
          title="Execute Flight?"
          description="Warning: Executing flights without proper on-site preparation or an on-site Part 107 pilot is prohibited. Before executing, ensure that the area is clear and that all safety precautions have been taken."
          confirmText="Execute"
          closeText="Cancel"
        />
      </MissionTaskFloatingSidebarContainer>
    </>
  );
};
