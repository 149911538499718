// External Dependencies
import { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { useNavigate, useBlocker, createSearchParams } from 'react-router-dom';
import { UseFormReturn } from 'react-hook-form';
import mapboxgl from 'mapbox-gl';
import {
  MissionResponse,
  DeviceResponse,
  DockDeviceIdsResponse,
} from '@raptormaps/raptor-flight-client-ts';
// RaptorUI
import { Text } from '@raptormaps/text';
import { Button } from '@raptormaps/button';
import { Sidebar } from '@raptormaps/sidebar';
import { useToast } from '@raptormaps/toast';

// Internal Dependencies
import { MissionTaskPopovers } from '../../missionTask';
import { parseFlightApiErrorDetails } from '@/shared/utils/utils';
import { generateMissionTaskInput } from '../../missionTaskUtils';
// Components
import DigitalTwinSection from '@/shared/components/DigitalTwin/DigitalTwin';
import BackgroundSection from '@/shared/components/Background/Background';
import {
  SidebarBottomPadding,
  Divider,
  CollapsibleTopPadding,
  SideBarContent,
} from '@/shared/styles/sidebar.styles';
import {
  MissionTaskFloatingSidebarContainer,
  MissionTaskCollapsableContainer,
  SidebarCompanionPopoverContent,
  BottomButtonsStack,
} from './CreateMissionTask.styles';
import { MissionTaskNameInput } from './components/MissionTaskNameInput';
import { MissionAndDeviceContainer } from './components/MissionAndDeviceContainer';
import { Popover, PopoverAnchor } from '@raptormaps/popover';
import { MissionListPopover } from './components/MissionListPopover/MissionListPopover';
import { MissionDetailPopover } from './components/MissionDetailPopover/MissionDetailPopover';
import { DeviceListPopover } from './components/DeviceListPopover/DeviceListPopover';
import { useCreateMissionTask } from '@/shared/hooks/useMissionTasks';
import { ConfirmationModal } from '@/shared/components/ConfirmationModal/ConfirmationModal';
import { DockDevice } from '@/shared/types/devices';

interface CreateMissionTaskProps {
  solarFarmId: number;
  activeMission?: MissionResponse;
  activeDevice?: DeviceResponse;
  isLoadingMission: boolean;
  map?: mapboxgl.Map;
  activeRowId: number;
  missionTaskForm: UseFormReturn;
  devices: DeviceResponse[];
  dockDeviceIds: DockDeviceIdsResponse[];
  dockDevices: DockDevice[];
  devicesLoading: boolean;
  setPreviewDeviceId: Dispatch<SetStateAction<number>>;
}

export const CreateMissionTask = ({
  solarFarmId,
  activeMission,
  isLoadingMission,
  map,
  activeRowId,
  missionTaskForm,
  devices,
  dockDeviceIds,
  dockDevices,
  devicesLoading,
  setPreviewDeviceId,
}: CreateMissionTaskProps) => {
  const toast = useToast();
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(true);
  const [visiblePopover, setVisiblePopover] =
    useState<MissionTaskPopovers | null>(null);
  const [backButtonPopover, setBackButtonPopover] =
    useState<MissionTaskPopovers | null>(null);
  const [invalidDroneForMission, setInvalidDroneForMission] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const createMissionTask = useCreateMissionTask(solarFarmId);
  const missionTaskInput = generateMissionTaskInput(missionTaskForm);
  const {
    formState: { isDirty },
  } = missionTaskForm;

  const onSaveMissionTask = async () => {
    try {
      return await createMissionTask.mutateAsync(missionTaskInput, {
        onSuccess: result => {
          toast.success(`Mission Task: ${result.id} Sucessfully Saved`, {
            duration: 10000,
          });
          navigate({
            pathname: `/plan-task/${result.id}`,
            search: createSearchParams({
              solar_farm_id: solarFarmId.toString(),
            }).toString(),
          });
        },
      });
    } catch (err) {
      const apiError = await parseFlightApiErrorDetails(
        err,
        'Error saving mission task. Please try again.',
      );
      const message = apiError[0]?.msg || apiError;
      toast.error(message, {
        duration: 10000,
      });
    }
  };

  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) => {
      const currentBasePathname = currentLocation.pathname.split('/')[1];
      // If we are not on the plan page, don't block
      if (currentBasePathname !== 'plan-task') return false;
      // If the form is not dirty, don't block
      if (!isDirty) return false;
      // If the pathname is changing and state is dirty, block
      const nextBasePathname = nextLocation.pathname.split('/')[1];
      if (currentBasePathname !== nextBasePathname) return true;
    },
    [isDirty],
  );

  const blocker = useBlocker(shouldBlock);
  return (
    <>
      <Popover open={visiblePopover !== null}>
        <MissionTaskFloatingSidebarContainer>
          <Sidebar
            title={`Execute Mission Task`}
            open={isSideBarOpen}
            onToggle={() => setIsSideBarOpen(sideBarOpen => !sideBarOpen)}
          >
            <PopoverAnchor />
            <SideBarContent align="start">
              <Button
                icon="ArrowLeft"
                iconPosition="left"
                size="small"
                variant="tertiary"
                role="link"
                style={{ fontWeight: '600', marginLeft: '-14px' }}
                onClick={() =>
                  navigate({
                    pathname: '/tasks',
                    search: `solar_farm_id=${solarFarmId}`,
                  })
                }
              >
                Back
              </Button>
              <Text variant="paragraph_small" align="left">
                To plan a mission task, select a device for data collection and
                select a mission for that device to execute.
              </Text>
              <MissionTaskNameInput missionTaskForm={missionTaskForm} />
              <Divider />
              <MissionAndDeviceContainer
                activeMission={activeMission}
                missionTaskForm={missionTaskForm}
                solarFarmId={solarFarmId}
                backButtonPopover={null}
                setBackButtonPopover={setBackButtonPopover}
                isLoadingMission={isLoadingMission}
                setIsSideBarOpen={setIsSideBarOpen}
                setVisiblePopover={setVisiblePopover}
                setInvalidDroneForMission={setInvalidDroneForMission}
              />
            </SideBarContent>

            <MissionTaskCollapsableContainer>
              <CollapsibleTopPadding />
              <DigitalTwinSection
                solarFarmId={solarFarmId}
                map={map}
                activeRowId={activeRowId}
              />
              <BackgroundSection
                map={map}
                solarFarmId={solarFarmId}
                useDroneIcon={false}
              />
              <SidebarBottomPadding />
              <BottomButtonsStack>
                <Button
                  variant="primary"
                  onClick={onSaveMissionTask}
                  disabled={
                    !missionTaskInput ||
                    invalidDroneForMission ||
                    missionTaskInput.missionIds.length < 1
                  }
                >
                  Save Mission Task
                </Button>
              </BottomButtonsStack>
            </MissionTaskCollapsableContainer>
          </Sidebar>
        </MissionTaskFloatingSidebarContainer>

        <SidebarCompanionPopoverContent
          align="end"
          alignOffset={0}
          side={'right'}
          sideOffset={isSideBarOpen ? 0 : 190}
          onPointerDownOutside={e => e.preventDefault()}
          onFocusOutside={e => e.preventDefault()}
          onInteractOutside={e => e.preventDefault()}
        >
          {visiblePopover === 'missionList' && (
            <MissionListPopover
              solarFarmId={solarFarmId}
              missionTaskForm={missionTaskForm}
              setBackButtonPopover={setBackButtonPopover}
              setVisiblePopover={setVisiblePopover}
              setIsSideBarOpen={setIsSideBarOpen}
            />
          )}
          {visiblePopover === 'missionDetail' && (
            <MissionDetailPopover
              solarFarmId={solarFarmId}
              missionId={activeMission?.id}
              backButtonPopover={backButtonPopover}
              missionTaskForm={missionTaskForm}
              setVisiblePopover={setVisiblePopover}
              setIsSideBarOpen={setIsSideBarOpen}
            />
          )}
          {visiblePopover === 'deviceList' && (
            <DeviceListPopover
              solarFarmId={solarFarmId}
              activeMission={activeMission}
              missionTaskForm={missionTaskForm}
              setBackButtonPopover={setBackButtonPopover}
              setVisiblePopover={setVisiblePopover}
              setIsSideBarOpen={setIsSideBarOpen}
              devices={devices}
              dockDeviceIds={dockDeviceIds}
              dockDevices={dockDevices}
              isLoading={devicesLoading}
              setPreviewDeviceId={setPreviewDeviceId}
            />
          )}
        </SidebarCompanionPopoverContent>
        <ConfirmationModal
          open={blocker.state === 'blocked'}
          onClose={() => blocker.reset()}
          handleCancel={() => blocker.reset()}
          handleConfirm={() => {
            blocker.proceed();
          }}
          title="Leave Mission Task?"
          description="Changes that you made will not be saved."
          confirmText="Leave"
          closeText="Cancel"
        />
      </Popover>
    </>
  );
};
