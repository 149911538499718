// Mapbox
import { Layers } from 'mapbox-gl';

// RaptorUI
import { Slider } from '@raptormaps/slider';
import { IconButton } from '@raptormaps/button';
import { Row } from '@raptormaps/layout';
import { Text } from '@raptormaps/text';

// Styled Components
import {
  StyledSliderRow,
  SliderLabelContainer,
  SliderContainer,
} from './MapLayerSlider.styles';

interface MapLayerSliderProps {
  name: string;
  opacity: number;
  layers: Layers;
  visible: boolean;
  disabled?: boolean;
  handleChangeOpacity: (opacity: number, layers: Layers) => void;
  handleHideShowLayer: () => void;
}

const MapLayerSlider = ({
  name,
  opacity,
  layers,
  visible,
  disabled,
  handleChangeOpacity,
  handleHideShowLayer,
}: MapLayerSliderProps) => {
  return (
    <StyledSliderRow
      align="center"
      justify="space-between"
      noWrap={true}
      disabled={disabled}
    >
      <SliderLabelContainer title={name} htmlFor={name}>
        {name}
      </SliderLabelContainer>
      <Row
        align="center"
        justify="space-between"
        gutter="none"
        noWrap={true}
        style={{ width: '55%' }}
      >
        <Text style={{ marginRight: '5px' }}>{opacity}%</Text>
        <SliderContainer>
          <Slider
            value={[opacity]}
            max={100}
            min={0}
            name={name}
            onChange={e => {
              handleChangeOpacity(e[0], layers);
            }}
            step={1}
            stepsBetweenThumbs={0}
            disabled={disabled}
          />
        </SliderContainer>
      </Row>
      <IconButton
        aria-label="toggle drone icons"
        icon={visible ? 'Eye' : 'EyeSlash'}
        size="medium"
        variant="secondary"
        onClick={handleHideShowLayer}
        style={{ marginLeft: '-10px' }}
      />
    </StyledSliderRow>
  );
};

export default MapLayerSlider;
