// External Dependencies
import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import mapboxgl from 'mapbox-gl';
import { Feature, FeatureCollection, Polygon } from '@turf/helpers';
import { useToast } from '@raptormaps/toast';
import dayjs from 'dayjs';
import utc from 'dayjs';
import { FlightModeType, DroneType } from '@raptormaps/raptor-flight-client-ts';

// Inernal Dependencies
import { PageProps } from '@/shared/types/page';
import { MissionTaskSchema } from './MissionTaskSchema';
import { MissionTaskFormValues } from './missionTask.d';
import { useGetMission, useGetMissions } from '@/shared/hooks/useMissions';
import { useUrlSearchParams } from '@/shared/hooks/useUrlParams';
import { useSolarFarmById } from '@/shared/hooks/useSolarFarms';
import { ERROR_TOAST_DURATION } from '@/shared/constants/appConstants';
import { handleCalculateMission } from './missionTaskUtils';
import {
  MISSION_TASK_FORM_FIELDS,
  MISSION_TASK_FORM_INITIAL_VALUES,
  MISSION_LIST_QURERY_PARAMS,
} from './missionTaskFormConstants';
import { useUrlParams } from '@/shared/hooks/useUrlParams';
import { useGetMissionTask } from '@/shared/hooks/useMissionTasks';
import {
  TelemetryWebsocketState,
  useTelemetryStore,
} from '@/shared/stores/TelemetryWebsocket/TelemetryWebsocketStore';
import { useShallow } from 'zustand/react/shallow';
import { useGetDevices } from '@/shared/hooks/useDevices';
import { useGetDockDeviceIds } from '@/shared/hooks/useDockDevices';
import { useGetDeviceModels } from '@/shared/hooks/useDeviceModels';
import {
  DeviceResponse,
  DeviceType,
  DockDeviceIdsResponse,
} from '@raptormaps/raptor-flight-client-ts';
import { DockDevice } from '@/shared/types/devices';

// Components
import { CreateMissionTask } from './components/CreateMissionTask/CreateMissionTask';
import { MissionTaskMap } from './components/MissionTaskMap';
import { FlightPlanner } from './components/FlightPlanner/FlightPlanner';
import { ExecuteFlight } from './components/ExecuteFlight/ExecuteFlight';
dayjs.extend(utc);

export const MissionTaskForm = ({ solarFarmId }: PageProps) => {
  const toast = useToast();
  const location = useLocation();

  const [map, setMap] = useState<mapboxgl.Map>(null);
  const [activeRowId, setActiveRowId] = useState<number>(null);
  const [waypoints, setWaypoints] = useState<FeatureCollection>(null);
  const [flightPath, setFlightPath] = useState<Feature>(null);
  const [polygon, setPolygon] = useState<Feature<Polygon>>(null);
  const [previewDeviceId, setPreviewDeviceId] = useState<number>(null);

  const missionTaskId = useUrlParams<number>('missionTaskId', 'number');

  const socketIsOpen = useTelemetryStore(
    useShallow((state: TelemetryWebsocketState) => state.socketIsOpen),
  );
  const actions = useTelemetryStore(
    useShallow((state: TelemetryWebsocketState) => state.actions),
  );

  //Errors
  const handleSolarFarmError = () =>
    toast.error(`Error fetching solar farm with ID ${solarFarmId}`, {
      duration: ERROR_TOAST_DURATION,
      dismissable: true,
    });
  const handleMissionError = () =>
    toast.error(`Failed to Fetch Mission ${missionIdForMap}`, {
      duration: ERROR_TOAST_DURATION,
      dismissable: true,
    });
  const handleDeviceModelsError = () =>
    toast.error(`Failed to Fetch Device Models`, {
      duration: ERROR_TOAST_DURATION,
      dismissable: true,
    });

  // Hooks
  const { data: solarFarm } = useSolarFarmById(
    solarFarmId,
    handleSolarFarmError,
  );
  const { data: apiMissionTask, isFetching: isFetchingMissionTask } =
    useGetMissionTask(missionTaskId, solarFarmId);
  const searchQueryParam =
    useUrlSearchParams<string>(MISSION_LIST_QURERY_PARAMS.search, 'string') ||
    '';
  const flightModeQueryParams =
    useUrlSearchParams<FlightModeType>(
      MISSION_LIST_QURERY_PARAMS.flightMode,
      'string',
    ) || '';
  const droneQueryParams =
    useUrlSearchParams<DroneType>(MISSION_LIST_QURERY_PARAMS.drone, 'string') ||
    '';
  const offset =
    useUrlSearchParams<number>(MISSION_LIST_QURERY_PARAMS.offset, 'number') ||
    0;
  const { data: missionList } = useGetMissions(
    solarFarmId,
    searchQueryParam,
    offset,
    droneQueryParams as DroneType,
    flightModeQueryParams as FlightModeType,
    handleMissionError,
  );

  const { data: devices, isLoading: devicesLoading } = useGetDevices({
    solarFarmId,
  });
  const { data: dockDeviceIds, isLoading: dockDevicesLoading } =
    useGetDockDeviceIds({ solarFarmId });

  const { data: models, isLoading: deviceModelsLoading } = useGetDeviceModels({
    solarFarmId,
    onError: handleDeviceModelsError,
  });

  const dockDevices: DockDevice[] = useMemo(() => {
    if (!devices || !dockDeviceIds || !models) return null;

    return dockDeviceIds.map((dockIdDeviceIds: DockDeviceIdsResponse) => {
      const dock = devices.solarFarmDevices.find(
        (device: DeviceResponse) => device.id === dockIdDeviceIds.dockId,
      );

      const dockDevices = dockIdDeviceIds.deviceIds.map((deviceId: number) => {
        return devices.solarFarmDevices.find(
          (device: DeviceResponse) => device.id === deviceId,
        );
      });

      const dockDroneModel = models.deviceModels.find(
        model =>
          model.id ===
          dockDevices?.find(
            device => device?.deviceType === DeviceType.AerialDrone,
          )?.deviceModelId,
      );

      return { ...dock, devices: dockDevices, dockDroneModel };
    });
  }, [devices, dockDeviceIds, models]);

  useEffect(() => {
    if (socketIsOpen && dockDevices) {
      actions.subscribeToDevices(dockDevices, solarFarmId);
    }
  }, [socketIsOpen, dockDevices]);

  // Form
  const missionTaskForm = useForm<MissionTaskFormValues>({
    defaultValues: MISSION_TASK_FORM_INITIAL_VALUES(apiMissionTask),
    mode: 'all',
    resolver: yupResolver(MissionTaskSchema),
  });
  useEffect(() => {
    missionTaskForm.reset(MISSION_TASK_FORM_INITIAL_VALUES(apiMissionTask), {
      keepDirty: false,
    });
  }, [apiMissionTask]);

  /* Map and Mission Management */
  const missionIds = missionTaskForm.watch(MISSION_TASK_FORM_FIELDS.missionIds);
  const {
    data: activeMission,
    isFetching,
    isLoading,
  } = useGetMission(missionIds[0], solarFarmId, handleMissionError);

  const detailViewMissionId = missionTaskForm.watch(
    MISSION_TASK_FORM_FIELDS.detailViewMissionId,
  );
  const selectedDockId = missionTaskForm.watch(MISSION_TASK_FORM_FIELDS.dockId);
  const missionIdForMap = detailViewMissionId || missionIds[0] || null;

  const missionForMap = missionList.missions?.find(
    mission => mission.id === missionIdForMap,
  );

  const { mapCenter } = missionForMap ? missionForMap : { mapCenter: null };

  useEffect(() => {
    if (!missionIdForMap) {
      setWaypoints(null);
      setFlightPath(null);
      setPolygon(null);
      return;
    }
    if (!missionForMap || !map) return;
    handleCalculateMission(
      map,
      missionForMap,
      setWaypoints,
      setFlightPath,
      setPolygon,
    );
  }, [missionForMap, missionIdForMap, map]);

  const handleFlightPlannerRouter = () => {
    if (location.pathname.startsWith('/flight')) {
      return (
        <ExecuteFlight
          solarFarmId={solarFarmId}
          map={map}
          activeRowId={activeRowId}
          missionTaskForm={missionTaskForm}
        />
      );
    } else {
      return missionTaskId ? (
        <FlightPlanner
          solarFarmId={solarFarmId}
          activeMission={activeMission}
          isLoadingMission={isFetching || isLoading || isFetchingMissionTask}
          map={map}
          polygon={polygon}
          waypoints={waypoints}
          activeRowId={activeRowId}
          dockId={missionTaskForm.getValues(MISSION_TASK_FORM_FIELDS.dockId)}
        />
      ) : (
        <CreateMissionTask
          solarFarmId={solarFarmId}
          activeMission={activeMission}
          isLoadingMission={isFetching || isLoading || isFetchingMissionTask}
          map={map}
          activeRowId={activeRowId}
          missionTaskForm={missionTaskForm}
          devices={devices?.solarFarmDevices}
          dockDeviceIds={dockDeviceIds}
          dockDevices={dockDevices}
          devicesLoading={
            devicesLoading ||
            dockDevicesLoading ||
            deviceModelsLoading ||
            isFetchingMissionTask
          }
          setPreviewDeviceId={setPreviewDeviceId}
        />
      );
    }
  };

  return (
    <>
      {handleFlightPlannerRouter()}
      <MissionTaskMap
        solarFarm={solarFarm}
        missionCenter={mapCenter}
        map={map}
        setMap={setMap}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
        waypoints={waypoints}
        flightPath={flightPath}
        polygon={polygon}
        selectedDockId={selectedDockId}
        previewDeviceId={previewDeviceId}
      />
    </>
  );
};
