// React
import { useState } from 'react';

// Mapbox
import { mapbox } from 'mapbox-gl';

// Customer API Types
import { TileMap } from '@raptormaps/customer-farm-api-client-ts';

// Styled Components
import MapLayerSlider from '../MapLayerSlider/MapLayerSlider';

interface AsBuiltControlsProps {
  map: mapbox.Map;
  asBuilt: TileMap;
}

const AsBuiltController = ({ map, asBuilt }: AsBuiltControlsProps) => {
  const [opacity, setOpacity] = useState(100);
  const [visible, setVisible] = useState(true);
  const layerID = 'raptor-as-built-tiles-' + asBuilt.id;

  const handleChangeOpacity = (opacity: number, layerId: string) => {
    map.setPaintProperty(layerId, 'raster-opacity', opacity / 100);
    setOpacity(opacity);
  };

  const handleHideShowAsBuilt = () => {
    if (map.getLayoutProperty(layerID, 'visibility') != 'none') {
      map.setLayoutProperty(layerID, 'visibility', 'none');
      setVisible(false);
    } else {
      map.setLayoutProperty(layerID, 'visibility', 'visible');
      setVisible(true);
    }
  };
  return (
    <MapLayerSlider
      name={asBuilt.filename}
      opacity={opacity}
      layers={layerID}
      visible={visible}
      handleChangeOpacity={handleChangeOpacity}
      handleHideShowLayer={handleHideShowAsBuilt}
    />
  );
};

export default AsBuiltController;
